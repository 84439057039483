//app/src/pages/SinglePortfolio.js

import React from "react";
import PageMeta from "../components/common/PageMeta";
import PortfolioDetails from "../components/portfolio/PortfolioDetails";
// import PortfolioPageHeader from "../components/portfolio/PortfolioPageHeader";
// import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import PortfolioImages from "../components/portfolio/PortfolioImages";

const SinglePortfolio = ({ project }) => {
  return (
    <div className={project.id}>
      <Layout>
        <PageMeta title={project.name} />
        <Navbar />
        {/* <PortfolioPageHeader project={project} /> */}
        <PortfolioDetails project={project} />
        <PortfolioImages project={project} />
        <section className="portfolio-videos pt-80">
          <div className="container">
            {project.assets.filter(asset => asset.url.endsWith(".mp4")).map(asset => (
              <video key={asset.url} width="auto" height="100%" controls>
                <source src={asset.url} type="video/mp4" />
              </video>
            ))}
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default SinglePortfolio;
