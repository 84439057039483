// app/src/components/portfolio/Portfolio.js
import React from "react";
import { Link } from "react-router-dom";
import portfolioData from "../../data/db.json";
import './style.css'; // Import your CSS file

const Portfolio = ({ imageWidth = 420, imageHeight = 310 }) => {
  const { projects } = portfolioData; // Destructure projects from portfolioData

  return (
    <section className="portfolio bg-dark ptb-120">
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-lg-6 col-md-12">
            <div className="section-heading text-left">
              <h2>Portfolio</h2>
            </div>
          </div>
        </div>

        {/* Tab Buttons (Uncomment if needed) */}
        {/* ... tab button code from your original file ... */}

        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
            <div className="row">
              {projects.map(project => (
                <div className="col-lg-4" key={project.id}>
                  <Link to={`/${project.id}`} className="text-decoration-none text-white">
                  <div className="single-portfolio-item mb-30" style={{ width: imageWidth, height: imageHeight }}>
                    <div className="portfolio-item-img" style={{ width: imageWidth, height: imageHeight }}>
                      <img
                        src={project.image}
                        alt={project.title}
                        className="img-fluid"
                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                      />
                      <div className="portfolio-info">
                        <h5>
                            {project.title}
                        </h5>
                        <div className="categories">
                          {project.platforms.map((platform, index) => (
                            <span key={index}>{platform}{index < project.platforms.length - 1 ? ", " : ""}</span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          {/* Other Tabs (Uncomment and adjust if needed) */}
          {/* ... other tab content from your original file ... */}

        </div>
      </div>
    </section>
  );
};

export default Portfolio;
