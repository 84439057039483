//app/src/routers/index.js

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeSassOne from "../themes/index1/HomeSassOne";
import NotFoundScreen from "../components/others/NotFoundScreen";
import ScrollToTop from "../components/common/ScrollToTop";
import SinglePortfolio from "../pages/SinglePortfolio";
import portfolioData from "../data/db.json";

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeSassOne />} />
        {portfolioData.projects.map((project) => (
          <Route
            key={project.id}
            exact
            path={`/${project.id}`}
            element={<SinglePortfolio project={project} />}
          />
        ))}
        <Route exact path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
