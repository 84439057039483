import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const PortfolioImages = ({ project }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // Filter out .mp4 files and convert project assets to a format compatible with react-photo-gallery
  const photos = project.assets
    .filter(asset => !asset.url.endsWith(".mp4"))
    .map(asset => ({
      src: asset.url,
      width: 4,
      height: 3,
      title: project.title,
    }));

  return (
    <section className="portfolio-images pt-100">
      <div className="container">
        <div className="row mt-5">
          <Gallery photos={photos} onClick={openLightbox} direction={"column"} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
    </section>
  );
};

export default PortfolioImages;
