import React, { Component } from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Portfolio from '../../components/portfolio/Portfolio';

class HomeSassOne extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='WL Technology' />
        <Navbar navDark />
        <Portfolio />
        {/* <HeroOne />
        <FeatureOne />
        <FeatureImgContentFour />
        <FeatureImg />
        <TestimonialOne darkBg />
        <WorkProcess />
        <PriceOne paddingTop='pt-60 pb-120' haspriceTitleWithHeader />
        <FaqOne />
        <IntegrationOne />
        <CtaOne />
        <FooterOne footerGradient /> */}
      </Layout>
    );
  }
}

export default HomeSassOne;
