import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ navDark }) => {
  // const [scroll, setScroll] = useState(0);
  // const [headerTop, setHeaderTop] = useState(0);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // setScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      // console.log("headerRef.current.offsetTop", headerRef.current.offsetTop)
      // setHeaderTop(50);
    }
  }, [headerRef]);

  return (
    <>
      <header
        ref={headerRef}
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header affix`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/logo-white.png"
                alt="logo"
                className="img-fluid logo-white"
              />
              <img
                src="assets/img/logo-color.png"
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
