import React from "react";

const PortfolioDetails = ({ project }) => {
  return (
    <section className="portfolio-details pt-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="portfolio-feature-img pb-60">
            <img
              src={project.image}
              alt={project.title}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <div className="portfolio-deatil-info">
              <h3 className="">{project.title}</h3>
              {project.description_bullets.map((bullet, index) => (
                <p key={index}>{bullet}</p>
              ))}
            </div>
          </div>
          <div className="col-lg-3">
            <ul className="list-unstyled">
              <li className="py-2">
                <h5>Client</h5>
                <span>{project.industry}</span>
              </li>
              <li className="py-2">
                <h5>Date</h5>
                <span>{project.date}</span>
              </li>
              <li className="py-2">
                <h5>Platforms</h5>
                <span>{project.platforms.join(", ")}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioDetails;
